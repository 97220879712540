import { useEffect, useMemo, useState } from "react";
import global from "../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import PageHeading from "Components/PageHeading";
import TableElement from "Components/TableElement";
import { SAVED_SEARCHES_CONFIG } from "./SavedSearches.config";
import { deleteSavedQuery, getSavedQueriesPaginated } from "api/search";
import Pagination from "Components/Pagination";
import ShowModalwindowDelete from "Routes/Dashboard/ShowModalWindowDelete";
import { DEFAULT_PAGE_COUNT } from "constants";
import TooltipContainer from "Components/Tooltip";
import SVGIcons from "Components/SVGIcons";
import { EntityGridPagination } from "Routes/Users/GroupPagination";
import moment from "moment";
import { SEARCH_TYPE } from "constants";
import { notify } from "Components/Notification";


const RowActions = props=>{
    const canSetDefault = true;
    const canDelete = true;
    return (
        <ul className={global.tableActions}>
            <li>
                <TooltipContainer title='Load Result' mouseLeaveDelay={0} placement='bottom'>
                    <span onClick={() =>  props.actionHandlers.loadHandler(props.record) }>
                    <SVGIcons type='SVG-import' />
                    </span>
                </TooltipContainer>
            </li>
            <li>
                <TooltipContainer title='Export Query' mouseLeaveDelay={0} placement='bottom'>
                    <span onClick={() => props.actionHandlers.exportHandler(props.record)}>
                        <SVGIcons type='SVG-export'/>
                    </span>
                </TooltipContainer>
            </li>
            {canSetDefault ? <li>
                <TooltipContainer title='Set as Default for current user' mouseLeaveDelay={0} placement='bottom'>
                    <span onClick={() => props.actionHandlers.setAsGroupDefaultQuery(props.record)}>
                        <SVGIcons type='SVG-set-as-default-group-search' />
                    </span>
                </TooltipContainer>
            </li> : null}

            {canDelete ? <li>
                <TooltipContainer title='Delete Query' mouseLeaveDelay={0} placement='bottom'>
                    <span onClick={() => props.actionHandlers.deleteHandler(props.record)}>
                        <SVGIcons type='SVG-delete' />
                    </span>
                </TooltipContainer>
            </li> : null}
      </ul>
    );
};

const formatDate = date =>{
    try{
        const result = moment(date).format("D MMMM YYYY");
        return result;
    }
    catch{
        return `${date}`;
    }
}


const makeColumns = (actionHandlers, sortSettings)=>{
    const makeSortOrder = columnKey=>{
        return sortSettings.field === columnKey ? sortSettings.order == "desc" ? "descend" : "ascend" : "";
    };

    return [{
        title: 'Query Name',
        dataIndex: 'name', 
        sorter: true,
        sortOrder: makeSortOrder('name'),

    }, 
    {
        title: 'Created By',
        dataIndex: 'createdBy',
        sorter: true,
        sortOrder: makeSortOrder('createdBy')
    }, 
    {
        title: 'Created Date',
        dataIndex: 'createdAt', 
        render: date => {
            return formatDate(date)
        },
        sorter: true, 
        sortOrder: makeSortOrder('createdAt')
    },
    {
        title: 'Actions', 
        key: 'actions',
        render: (text, record)=><RowActions record={record} actionHandlers={actionHandlers}/>
    }];
};

export const SavedQueriesModalContent = props=>{

    const [savedQueries, setSavedQueries] = useState([]);
    
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [pagination, setPagination] = useState ({ pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT });

    
    const [sortSettings, setSortSettings] = useState({ field: 'createdAt', order: 'desc' });

    const load = ()=>{
        setIsLoading(true);
        const payload = {
            pageSize: pagination.pageSize, 
            pageNumber: pagination.pageNumber,
            sortField: sortSettings.field, 
            sortOrder: sortSettings.order
        };

        getSavedQueriesPaginated(payload).then(res=>{
            debugger;

            setSavedQueries(res.data.items); 
            setTotalCount(res.data.totalCount); 
        }).finally(()=>{
            setIsLoading(false);
        });
    };

    useEffect(()=>{
        load();
    }, [pagination, sortSettings]);

    const doExport = record=>{
        // this is to replace HomeHeader.exportHandler

        let payload = null;
        // searchState is the full content of the SearchData field
         try{
            payload = JSON.parse(record.searchState);
        }
        catch (ex){
            console.log('error parsing the default query payload', ex);
            return;
        }

        let textData;
        if (payload.v2){            
            textData = JSON.stringify(payload.v2);
        }
        else {
            // the query is in the old format
            // metaData = record.searchState;

            let x  = JSON.parse(record.searchState);
            let metaData = JSON.parse(x.Metadata);
            textData = metaData.searchType == SEARCH_TYPE.NORMAL_SEARCH
                ? handleFormatTextData(metaData)
                : handleFormatAdvanceSearchTextData({
                    data: metaData.advanceSearchPayloadData,
                    searchWord: metaData.searchWords,
                    value: metaData.value,
                });
        }

        const element = document.createElement("a");
        const file = new Blob([textData], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "Saved Query " + record.name + ".txt";
        document.body.appendChild(element);
        element.click();
        element.remove();
    };


    const deleteHandler = record=>{
        setShowDeleteSavedQueryConfirmationDialog(record);
    };

    const loadHandler = props.handleLoadQuery;

    const exportHandler = doExport;


    const columns = useMemo(()=>{
        const result = makeColumns({
            loadHandler, exportHandler, deleteHandler
        }, sortSettings);
        return result;

    }, [sortSettings, loadHandler, exportHandler, deleteHandler]);

    
    const handlePaginationChange = (pageNumber, pageSize)=>{
        setPagination({ pageNumber, pageSize });    
    };

    const handleSortingChange = (a, b, sorter)=>{
        const newSortSettings = {
            field: sorter.field,
            order: sorter.order == 'descend' ? 'desc' : 'asc'
        };
        setSortSettings(newSortSettings);
    };

        

    const [showDeleteSavedQueryConfirmationDialog, setShowDeleteSavedQueryConfirmationDialog] = useState(null); // another modal dialog that confirms the deleetion of a saved query


    const doDeleteQuery = ()=>{
        // user clicked to confirm the deletion of a query, the query object is in showDeleteSavedQueryConfirmationDialog state
        // we cannot delete a query that was marked as default so it's okay to run the delete here
        debugger;
        const queryId = showDeleteSavedQueryConfirmationDialog.id;
        deleteSavedQuery({id: queryId}).then(res=>{
            setShowDeleteSavedQueryConfirmationDialog(null);
            notify.success("Query deleted");
            // and reload the data: 
            if (savedQueries.length == 1 && pagination.pageNumber > 1){
                setPagination({...pagination, pageNumber: pagination.pageNumber - 1});
            }
            else{
                load();
            }
        });
    };



    return (<div className={style.savedSearch}>
        {/* this one is for confirmation: */}
        {/* <ShowModalwindow {...props} /> */}

        {showDeleteSavedQueryConfirmationDialog ? <ShowModalwindowDelete 
            state={{showDeleteModal: true}} 
            deleteModalHandler={()=>setShowDeleteSavedQueryConfirmationDialog(null)} 
            deleteModalHandlerYes={doDeleteQuery} /> : null}


        <PageHeading text={"Saved Searches"}>
            <div className={global.searchresultCount}>
                <span> {savedQueries.length > 0 ? `Showing ${savedQueries.length} out of ${totalCount} results` : ''}</span>
            </div>
        </PageHeading>

        {/* TODO: check how ContentLoader is used here, see savedSearches/index.js */}
        
        <div className={style.savedSearch__tbldata}>
            <TableElement
                rowKey='id'
                columns={columns}
                // columns={SAVED_SEARCHES_CONFIG.COLUMNS({
                //     setAsGroupDefaultQuery: props.setAsGroupDefaultQuery, 
                //     defaultQueryId: props.defaultQueryId,
                //     userProfile: {
                //         isGroupAdmin: props.isGroupAdmin,
                //         userProfile: props.userProfileId,
                //         emailid: props.userEmail
                //     }, 
                //     deleteHandler: (record)=>setShowDeleteSavedQueryConfirmationDialog(record),
                //     loadHandler: props.handleLoadQuery, 
                //     exportHandler: doExport,
                // })}
                data={savedQueries}
                onChange={handleSortingChange}
                scrollY={400}
            />

            <div className={global.searchresultPage}>
                {totalCount > DEFAULT_PAGE_COUNT ? <EntityGridPagination 
                    pagination={pagination} totalCount={totalCount} 
                    handlePaginationChange={handlePaginationChange}
                /> : null}
            </div>
            {/* {totalCount > 5 ? <Pagination
                pageSize={pageSize}
                current={pageNumber}
                total={totalCount}
                onChange={handlePaginationChange}
                showSizeChanger={true}
                pageSizeOptions={[5, 10, 20, 50]}
            /> : null} */}
            
            
           
        </div>
        
        {/* {props.state.exportLoader && <LoaderModal />} */}
        
    </div>);
};