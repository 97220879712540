import global from "../../styles/globel/style.module.scss";
import { useEffect, useRef, useState } from "react";

import { FilterPanel } from "Components/MyFilter/FilterPanel";
import { MyHeaderSection2 } from "Components/HeaderSection/MyHeaderSection2";
import { TabsWithSearchResults } from "./components/SearchTabElements/TabsWithSearchResults";
import { INSIGHT_OPENING_MESSAGE } from "./constants";
import ModalWindow from "Components/ModalWindow";
import { ROUTES } from "Constants/Routes/Routes.constants";
import { TaxonomyFilterChips } from "Components/HeaderSection/TaxonomyFilterChips/TaxonomyFilterChips";
import { FilterPanel2 } from "Components/MyFilter/FilterPanel2";



const MyHome2 = props=>{
  
    const [isInsightModalOpen, setIsInsightModalOpen] = useState(false);
    const [taxonomyFilterOpened, setTaxonomyFilterOpened] = useState(false); // TODO: this needs to be in the mainFrame 


    // useEffect(()=>{
    //     props.runSearch();
    // }, []);

    const handleInsightOpenModalOnOk = ()=>{
        props.openInsightsModal();
        setIsInsightModalOpen(false);
    };

    const handleInsightOpenModalOnClose = () => {
        // hide the modal dialog
        setIsInsightModalOpen(false);
    };


    return (      
    <div className={`${global.layoutBlock} ${taxonomyFilterOpened ? global['filterToggle'] : ""} `}>
        <div className={global.pageAside} style={{ display: 'flex', flexDirection: 'row'}}>

            <FilterPanel 

                sortOrder={props.sortOrder}
                clearAllTaxonomyFilters={props.clearAllTaxonomyFilters}
                projectId={props.projectId}

                updateLoadedAndCheckedTaxonomies={props.updateLoadedAndCheckedTaxonomies}

                runSearch={()=>props.runSearch()} 
                
                previousAllFilterRows={props.previousAllFilterRows}
                filterRowsDelta={props.filterRowsDelta}
                
                taxonomies={props.taxonomiesForFilterPanel}
                // needDateFilter={props.needDateFilter}
                externalData={props.externalData}
            />

        </div>


        <div className={global.pageContent}>
            <div className={global.resultWrapper}>

                <MyHeaderSection2 runSimpleSearch={props.runSimpleSearch} 
                    exportToXls={props.exportToXls}
                    exportLoading={props.exportLoading}
                    projectId={props.projectId}
                    saveSearchSettings={props.saveSearchSettings}
                    simpleSearchText={props.simpleSearchText}
                    setAdvancedSearchVisible={props.setAdvancedSearchVisible} 
                    advancedSearchVisible={props.advancedSearchVisible} 
                    advancedSearchRows={props.advancedSearchRows} 
                    clearAdvancedSearch={props.clearAdvancedSearch}
                    viewType={props.viewType}

                    syncScope={props.syncScope}

                    sortingType={props.sortingType}
                    sortingOrder={props.sortingOrder}
                    handleSortingTypeChange={props.handleSortingTypeChange}
                    handleSortingOrderChange={props.handleSortingOrderChange}

                    taxonomyFilterOpened={taxonomyFilterOpened}
                    setTaxonomyFilterOpened={setTaxonomyFilterOpened}

                    handleViewInsightsButtonClick={()=>setIsInsightModalOpen(true)}

                    userProfileId={props.userProfileId}
                    isGroupAdmin={props.isGroupAdmin}
                    userEmail={props.userEmail}

                    handleLoadQuery={props.handleLoadQuery}

                    defaultQueryId={props.defaultQueryId}
                    setAsGroupDefaultQuery={props.setAsGroupDefaultQuery}
                />
    
                <TaxonomyFilterChips loadedTaxonomies={props.loadedTaxonomies} checkedTaxonomies={props.checkedTaxonomies} dateFilters={props.dateFilters} updateLoadedAndCheckedTaxonomies={props.updateLoadedAndCheckedTaxonomies} />

                <TabsWithSearchResults 
                    cloudTaxonomy={props.cloudTaxonomy}
                    cloudSearchWords={props.cloudSearchWords} 
                    handleClearCloudSearchWordsClicked={props.handleClearCloudSearchWordsClicked}

                    excludedWords={props.excludedWords}
                    handleClearExcludedWordsClicked={props.handleClearExcludedWords}

                    markedDocumentIds={props.markedDocumentIds} 
                    setMarkedDocumentIds={props.setMarkedDocumentIds}

                    dateFormat={props.dateFormat}

                    viewLineCountSettings={props.viewLineCountSettings}

                    updateUserTags={props.updateUserTags}
                    showAllTags={props.showAllTags}
                    setShowAllTags={props.setShowAllTags}

                    showFullContent={props.showFullContent}
                    toggleShowFullContent={props.toggleShowFullContent}

                    simpleSearchText={props.simpleSearchText}
                    advancedSearchRows={props.advancedSearchRows}

                    activeTabKey={props.activeTabKey} 
                    handleTabChange={props.handleTabChange}
                    searchResult={props.searchResult} 
                    setSearchResult={props.setSearchResult}
                    metaDataList={props.taxonomiesAsMetadata} 
                    handleViewTypeChange={props.handleViewTypeChange} viewType={props.viewType}

                    currentPage={props.pageNumber}
                    pageSize={props.pageSize}
                    totalCount={props.searchResult.totalCount}
                    handlePageNumberChange={props.handlePageNumberChange}
                    showPagesizeChanger={props.showPagesizeChanger}

                    allProjectTaxonomies={props.allProjectTaxonomies}

                />
  
            </div>
        </div>

        {isInsightModalOpen ? <ModalWindow
            open={true}
            title={"Opening Insights"}
            width={900}
            okText="Ok"
            cancelText="Cancel"
            onOk={handleInsightOpenModalOnOk}
            onCancel={handleInsightOpenModalOnClose}
            isNonMaskable={true}
            isNotClosable={true}
            // isHideCancelButton={true}
        >
            <h5>{INSIGHT_OPENING_MESSAGE} </h5>
        </ModalWindow> : null}

    </div>);
};

export default MyHome2;