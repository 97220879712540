import { WordCloud2 } from "Components/WordCloud/WordCloud2";
import { wordCloudDataFormatter } from "Routes/Insights/components/CreateInsights/ChartContainer";
import { BarChart } from "Components/Barchart";
import ApexHeatMap from "Components/ApexHeatMap";
import { random } from "lodash";

export const ChartSwitcher = props=>{
    debugger;
    const chart = props.chart;
    const chartType = chart.chartType;

    if (!chart.dataItems){
        return null;
    }

    
    // {text: 'Ceo', key: 'ceo', value: 14564, type: '3. Top N KeyPhrases', parent: undefined}
    if (chartType=='KEY_PHRASE_CLOUD'){
        // debugger;
        const mappedItems = wordCloudDataFormatter(chart.dataItems);
        return <WordCloud2 isForDashboard={true} items={mappedItems} />
    }

    if (chartType == 'BAR_CHART'){
        mappedItems = chart.dataItems.map(x=>({key: x.taxonomyValue, value: x.count}));
        return  (<BarChart key={JSON.stringify(chartItems)}
        
            data={mappedItems} config={{cursor: true, fieldValue: props.option1, grid: false, legend: true, panX: false, panY: false, seriesTooltip: true, xAxis: 'key', yAxis: 'value'}}

            id={JSON.stringify(chartItems)}
        />);
    }

    if (chartType == 'HEAT_MAP'){
        const mappedItems = chart.dataItems;
        return  (<ApexHeatMap 
            key={JSON.stringify(mappedItems)}  
            data={mappedItems} config={{categoryField: 'xAxis', categoryXField: props.option1, categoryYField: props.option2, panX: false, panY: false}} 
            id={"HeatMap" + random(0, 1e6)}
        />);
    }

    return null;
}