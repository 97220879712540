import ButtonElement from "Components/ButtonElement";
import EmptyState from "Components/EmptyState"
import { useEffect, useState } from "react";
import { ImportInsightsModal } from "./ImportInsightsModal";
import { getInsightsChartDataById } from "api/insights";
import { DashboardInfoTable } from "./Components";
import { DashboardInfoTable2 } from "./Components/DashboardInfoTable2";
import { ChartsInGridExample2 } from "./ChartsInGridExample2";
import { ChartsInGridExample } from "./ChartsInGridExample";
import { DashboardToolbar } from "./DashboardToolbar";
import { LoadDashboardsModal } from "./LoadDashboardsModal";
import { SaveDashboardsModal } from "./SaveDashboardsModal";
import { addDashboard, deleteDashboard, updateDashboard } from "api/dashboard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "Constants/Routes/Routes.constants";
import { DeleteDashboardModal } from "./DeleteDashboardModal";
import { notify } from "Components/Notification";

export const Dashboard2 = props=>{

    // A dashboard is a combination of "insights" and some tabular "Matched Results"
    // An insight is a record from "Insights" table, it is a chart (it has chart type, eg cloud, heatmap, barchart etc) and a selection of filters. All these are stored in the "Insights" table.
    // In order to be included into a dashboard, the insight needs to be saved. 
    // We can combine multiple charts to make up a dashboard - for that we need to have a list of Chart IDs (IDs from insights table).
    // The ImportInsightsModal allows to select the pre-saved insight to be included on the dashboard

    const [importInsightsModalVisible, setImportInsightsModalVisible] = useState(false); // modal for listing saved insights

    const [loadDashboardsModalVisible, setLoadDashboardsModalVisible] = useState(false); // modal for listing saved dashboards
    const [saveDashboardsModalVisible, setSaveDashboardsModalVisible] = useState(false); // modal for saving a dashboard 

    const [deleteDashboardModalVisible, setDeleteDashboardModalVisible] = useState(null);

    const [selectedInsights, setSelectedInsights] = useState([]);
    
    const [name, setName] = useState('');
    const [dashboardId, setDashboardId] = useState(0);

    // this is where we put the charts data (for each selected insight)
    const [charts, setCharts] = useState([]); 
  
    // if there is a dashboard  (then display/edit mode) or not (then show "the menu")
    const [hasDashboard, setHasDashboard] = useState(false);

    // flag that the current dashboard is in the edit mode
    // Edit mode means can adjust the position of the individual charts
    const [editMode, setEditMode] = useState(false);


    const clearAll = ()=>{
        setCharts([]);
        setSelectedInsights([]);
        setHasDashboard(false);
        setEditMode(false);
        setName('');
        setDashboardId(0);
    };


    const confirmSave = (dashboardName)=>{
        // here we save the dashboard. This can be 
        debugger;

        const payload = {
            dashboardId, 
            includedInsights: [...selectedInsights],
            layout: JSON.stringify(chartsLayouts),
            dashboardName,
            projectId: props.projectId
        };

        if (dashboardId){
            // make the API call: 
            updateDashboard(payload).then(res=>{
                debugger;
                setSaveDashboardsModalVisible(false);
            }).catch(err=>{
                debugger;
                // TODO: show errors somewhere, handle the case where name is duplicate
            });
        }
        else {
            addDashboard(payload).then(res=>{
                debugger;
                setSaveDashboardsModalVisible(false);
                setDashboardId(res.data.result.id);
                setName(dashboardName);
            }).catch(err=>{
                debugger;
                // TODO: show errors somewhere, handle the case where name is duplicate
            });

        }
        
    };

    
    const loadCharts = (insightIds)=>{
        debugger;
        Promise.all(insightIds.map(id=>{
            return getInsightsChartDataById(id);
            // Actually it's better to have another API method that returns chart data directly. 
            // No need to make double trip
        })).then(res=>{
            console.log(res);
            //setCharts(res.map(r=>r.data)); // each chart will have items 

            const x = res.map(r=>r.data);
            setCharts(
                x.map((x, id)=>({...x, id }))
            ); // each chart will have items 



            // now depending on what is the chartType, we need to call different endpoints
            // then the returned data series will have to be rendered according to their chart type 

            setImportInsightsModalVisible(false); // hide the modal

            
            setHasDashboard(true); // hide the buttons
            setEditMode(true); // the chart is in the edit mode 
        });
    };

   

    useEffect(()=>{
        if (props.insights){
            setSelectedInsights(props.insights);
            loadCharts(props.insights);
        }
    }, [props.insights]);

    const onConfirmSelection = selectedItems=>{
        
        setSelectedInsights(selectedItems);

        loadCharts(selectedItems);
        
    };

    // chartsLayouts are for different screen resolutions
    const [chartsLayouts, setChartsLayouts] = useState(null);
    const updateTrackedLayouts = layouts=>{
        // debugger;
        // console.log('updateTrackedLayouts')
        // these are layouts and breakpoints
        setChartsLayouts(layouts);
    };


    // return different content based on stage: 
    // chartsReady == false - return the DashboardWelcomeImage with two buttons.
    // chartsReady == true - return the toolbar with Clear All, Save, Import insights plus the combo of ChartsInGrid plus table 


    const [loadedLayouts, setLoadedLayouts] = useState(null);

    const handleViewDashboard =record=>{
        // 1) we need to load the insights for the dashboard

        // 2) we need to apply the layout 

        debugger;
        Promise.all(record.includedInsights.map(id=>{
            return getInsightsChartDataById(id);
        })).then(res=>{
            debugger;

            const x = res.map(r=>r.data);
            setCharts(
                x.map((x, id)=>({...x, id }))
            ); //


            let layout = null;
            if (record.layout){
                layout = JSON.parse(record.layout);
                console.log(layout);
                if (layout.allLayouts){
                    setLoadedLayouts(layout.allLayouts);
                }
            }

            setName(record.dashboardName);
            setDashboardId(record.id);
            setSelectedInsights(record.includedInsights);

            setLoadDashboardsModalVisible(false);
            setHasDashboard(true);
            setEditMode(false);
        });
    };

    const history = useHistory();

    const handleDownloadDashboard = record=>{
        debugger;
        // this simply takes the user to Download route: 
        history.push({
            pathname: ROUTES.DOWNLOAD_PREVIEW, 
            search: '?dashboard', 
            state: {
                dashboardName: record.dashboardName,
                isDashboard: true,
                id: record.id, 
                layout: record.layout,
                includedInsights: record.includedInsights,
                breadcrumbs: [
                    {label: 'Dashboard Cancas', link: '/dashboard'},
                    {label: 'Download Dashboard', link: ''}
                ]
            }
        });
    };

    const handleDeleteDashboard = record=>{
        setDeleteDashboardModalVisible(record);
    };

    const [triggerReload, setTriggerReload] = useState({});

    const confirmDelete = ()=>{
        // make the API call, do the notify.success
        deleteDashboard({id: deleteDashboardModalVisible.id}).then(res=>{
            debugger;
            notify.success(`Dashboard ${deleteDashboardModalVisible.dashboardName} deleted.`, true);
            // need to refresh the list of dashboard
            setTriggerReload({});
            setDeleteDashboardModalVisible(null);

        }).catch(err=>{
            debugger;
        });
    };

    return (<>
        {hasDashboard ? 
        <div>
            <DashboardToolbar editMode={editMode} 
                saveDashboard={()=>setSaveDashboardsModalVisible(true)}
                clearAll={()=>clearAll()}
                closeDashboard={()=>{
                    setHasDashboard(false);
                    setEditMode(false);
                }} 
                toEditMode={()=>setEditMode(true)}
                addInsights={()=>setImportInsightsModalVisible(true)}
            />
            {name ? <div>
                <h3>{name}</h3>
            </div> : null}
        
            <ChartsInGridExample2 charts={charts} updateTrackedLayouts={updateTrackedLayouts} loadedLayouts={loadedLayouts} isEditMode={editMode}/>
            <DashboardInfoTable2 charts={charts}/>
        </div> 
        : <EmptyState
            type="dashboardWelcomeImage"
            content={"DASHBOARD_INITIAL_STATE_TAB"}
        >
            <div style={{display: 'flex', gap: '20px'}}>
                <ButtonElement onClick={()=>setImportInsightsModalVisible(true)}>Import Insights</ButtonElement>
                <ButtonElement onClick={()=>setLoadDashboardsModalVisible(true)}>Load Dashboard</ButtonElement>
            </div>
        </EmptyState>}

        {importInsightsModalVisible ? <ImportInsightsModal onCancel={()=>setImportInsightsModalVisible(false)} 
            onConfirmSelection={onConfirmSelection} 
            selectedInsights={selectedInsights} /> : null}

        {loadDashboardsModalVisible ? <LoadDashboardsModal 
            triggerReload={triggerReload}
            handleView={handleViewDashboard}
            handleDownload={handleDownloadDashboard}
            handleDelete={handleDeleteDashboard}
            onCancel={()=>setLoadDashboardsModalVisible(false)} /> : null}

        {saveDashboardsModalVisible ? <SaveDashboardsModal 
            name={name} 
            onCancel={()=>setSaveDashboardsModalVisible(false)} 
            onOk={confirmSave}/> : null}

        {deleteDashboardModalVisible ? <DeleteDashboardModal 
            onCancel={()=>setDeleteDashboardModalVisible(null)} 
            onOk={confirmDelete}/> : null}

    </>);

};
