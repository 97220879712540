import { Responsive, WidthProvider } from "react-grid-layout";
import { ChartSwitcher } from "./ChartSwitcher";
import "./ChartInGridStyles.scss";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import _ from "lodash";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const ChartsInGridExample2 = props=>{

    const gridRef = useRef(null);
    const widthDelta = 16; // delta of the div with breakpoints
    const windowWidthDelta = -61;  // delta of window.innerWidth and breakpoints

    const [gridWidth, setGridWidth] = useState(0); // this is the width of the client rect with dragResize control. 
    // important! breakpoints are set to the size of this area, not to the size of the screen
    // widthDelta is some magic number difference between gridWidth and breakpoints boundaries, 
    // eg gridWith 980 means md: 996
    // gridWidth 1184 means lg: 1200

    // !!! onBreakPointChange returns breakpoints relevant to the size of the div, not size of the screen 
    

    const [windowWidth, setWindowWidth] = useState(0);
    useEffect(()=>{
        const gridRefTracker = ()=>{
            // debugger
            if (gridRef.current){
                setGridWidth(gridRef.current.offsetWidth);
                //setWindowWidth(document.documentElement.clientWidth);
                setWindowWidth(window.innerWidth); // this is the width of the browser window (not the div that contains the drag control). Then breakpoints apply to the browser window size
            }
        };

        window.addEventListener("resize", gridRefTracker);
        gridRefTracker();
        return ()=>window.removeEventListener("resize", gridRefTracker);
    }, []);
    const charts = props.charts;
    
    // breakpoints is an object with different dimentions:
    const breakpoints = { lg: 1200, md: 996, sm: 768 };

    const cols = { lg: 6, md: 6, sm: 6};

    const itemHeightInRows = 3;
    
    // layouts object has keys for each breakpoint (lg, md, sm)
    // value of each property is an array of items - how we arrange individual tiles on the screen
    // for 1 and 2 items we arrange them in a fixed way, for more than 2 - create a mapping
    const initialLayouts = useMemo(()=>{

        if (props.loadedLayouts){
            return {...props.loadedLayouts};
        }
        // needs to return object with three arrays:
        const lg = [];
        const md = [];
        const sm = [];

        // we need to create layouts object for each item in the sequence (eg if there are 8 items over 4 rows-  we need 8 items in the layouts array)

        if (charts.length == 1){
            const item1 = {
                i: 'item1', 
                x: 0, 
                y: 0, 
                w: 6, 
                h: itemHeightInRows
            };
            lg.push(item1);
            md.push(item1);
            sm.push(item1);
        }
        else if (charts.length == 2){            
            const item1 = {
                i: 'item1', 
                x: 0, 
                y: 0, 
                w: 3, 
                h: itemHeightInRows
            };

            const item2 = {
                i: 'item2', 
                x: 3, 
                y: 0, 
                w: 3, 
                h: itemHeightInRows
            };

            lg.push(item1);
            md.push(item1);
            
            lg.push(item2);
            md.push(item2);

            sm.push({...item1, w: 6});
            sm.push({...item2, w: 6});

        } else {
            const w = {lg: 2, md: 3, sm: 6};
            
            let xPos = {lg: 0, md: 0, sm: 0};
            //let yPos = 0;
            for (let i = 0; i < charts.length; i++){
                
                const item = {
                    i: `item${i+1}`, // key must be in this format
                    //x: xPos, //'',  // 0/4/8 or 0/6
                    //y: yPos, // needs to be recalculated
                     y: 0, // seems to be okay to leave it as 0
                    // w: step,
                    h: itemHeightInRows
                };
                lg.push({...item, w: w.lg, x: xPos.lg});
                md.push({...item, w: w.md, x: xPos.md});
                sm.push({...item, w: w.sm, x: xPos.sm});
                
                xPos = {
                    lg: xPos.lg + w.lg, 
                    md: xPos.md + w.md, 
                    sm: xPos.sm // + w.sm -- this doesn't change
                };
                if (xPos.lg == 6){
                    xPos.lg = 0;
                };
                if (xPos.md == 6){
                    xPos.md = 0;
                }

                // if (xPos == 0){
                //     yPos += 1;
                // }

            }
            
        }

    return {lg, md, sm};
    }, [charts, itemHeightInRows, props.loadedLayouts]);
   
    const [trackedLayouts, setTrackedLayouts] = useState({});  // layout will be recorded on first render

    useEffect(()=>{
        debugger;
        console.log('effect with initial Layouts')
        // need this effect to update the layouts at first load
        setTrackedLayouts(initialLayouts);
        // if (props.updateTrackedLayouts){
        //     props.updateTrackedLayouts(initialLayouts);
        // }
    }, [initialLayouts]);


    // we might want the breakpoint for saving the dashboard state
    const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
    const onBreakpointChange = (newBreakpoint, cols)=>{
        setCurrentBreakpoint(newBreakpoint);
        return;
        // debugger;
        // // another problem: currentLayout might not be updated when onBreakpointChange happens
        // console.log('onBreakpointChange', newBreakpoint, cols, currentLayout);
        // // newBreakpoint is lg/md/sm
        // const newLayout = {...trackedLayouts, [newBreakpoint]: currentLayout};
        // setTrackedLayouts(newLayout);
        
    };

    // state for watching the current layout
    //const [currentLayout, setCurrentLayout] = useState({});


    // useEffect(()=>{
    //     if (!Object.keys(currentLayout).length || !gridWidth){
    //         return;
    //     }
    //     // possibly need this effect...
    // // but we must run it when currentLayout changes, not just when gridWidth changes. However, gridWidth change would mean the same render cycle
    // // It is impossible to change the size of the div without triggering onLayoutChange event

    // // 1) need to determine which layout to update:
    //     const adjustedWidth = gridWidth + widthDelta;
    //     let key = 'lg';
    //     if (adjustedWidth < breakpoints.lg) {
    //         key = 'md';
    //     }
    //     if (adjustedWidth < breakpoints.md) {
    //         key = 'sm';
    //     }

    //     const newLayouts = {...trackedLayouts, [key]: currentLayout};
    //     setTrackedLayouts(newLayouts);


    // }, [currentLayout, gridWidth]);

    const handleDragAndResize = (layout)=>{
        // at this point we have the breakpoint which has been recorded
        // and we have the layout. So just set it
        debugger;
        // layoutData is for each item
        const layoutData = layout.map(item=>{
            const { i, x, y, w, h } = item;
            return { i, x, y, w, h };
        });
        const newLayouts = {...trackedLayouts, [currentBreakpoint]: layoutData};
        setTrackedLayouts(newLayouts);

        if (props.updateTrackedLayouts){
            // currentLayouts is a set of layouts for all breakpoints. 
            // currentBreakpoint is the screen size at the moment (lg, md, sm)

            // we need to record all the currentLayouts (for all sizes) and the breakpoints data as well:
            props.updateTrackedLayouts({currentLayouts: newLayouts, breakpoints});
        }
    };

    const onDragStop=(layout, oldItem, newItem, placeholder, e, element)=>{
        debugger;
        if (JSON.stringify(oldItem) == JSON.stringify(newItem)) {
            return;
        }
        console.log(layout, oldItem, newItem, placeholder, e, element);
        handleDragAndResize(layout);
    };

    const onResizeStop = (layout, oldItem, newItem, placeholder, e, element)=>{
        debugger;
        if (JSON.stringify(oldItem) == JSON.stringify(newItem)) {
            return;
        }
        console.log(layout, oldItem, newItem, placeholder, e, element);
        handleDragAndResize(layout);
    };

    const onLayoutChange2 =(currentLayouts, allLayouts)=>{
        // debugger;
        // currentLayouts is the currenly applied layout (just one, not lg/md/sm), but for each item
        // allLayouts is just a subset of the all layouts for the current breakpoint
        // JSON.stringify(allLayouts.lg) == JSON.stringify(currentLayouts)


        console.log('onLayoutChange2', currentLayouts);

        const newLayouts = currentLayouts.map(item=>{
            const { i, x, y, w, h } = item;
            return { i, x, y, w, h };
        });
        //setCurrentLayout(newLayouts);

        if (props.updateTrackedLayouts){
            // we only need allLayouts and breakpoints to show the grid from persisted data
            // currentLayouts is the allLayouts for current screen size (tells us which breakpoint was active)
            props.updateTrackedLayouts({
                currentLayouts: newLayouts, 
                allLayouts: trackedLayouts, 
                breakpoints
            });
        }
    };

    const onLayoutChange = (currentLayouts, allLayouts)=>{
        if (!gridRef.current){
            return;
        }
        // the problem is that this event does not apply the correct layout that corresponds to the screen size 
        // Solution: just remember the current layout here and chuck it into the trackedLayout in the onBreakpointChange event

        // Update: onLayoutChange happens when dragging finished - wrong. Happens all the time....
        
        console.log('currentLayouts', currentLayouts, 'allLayouts', allLayouts, gridWidth);
        // debugger;
        // happens when the layout is changing due to dragging tiles or resizing the screen
        const sizingData = currentLayouts.map(item=>{
            const { i, x, y, w, h } = item;
            return { i, x, y, w, h };
        });

        // need to determine which part of the layouts needs to be updated: 

        //setTrackedLayouts(sizingData);

        //debugger;

        
        //setCurrentLayout(sizingData); - this done via props in the outer container 

        // assuming onLayoutChange and gridWidth change happen in the same render cycle
        // return;

        //const width = window.innerWidth + windowWidthDelta;
        
        const width2 = gridWidth + widthDelta;
        //const width = document.getElementById('d').offsetWidth; // it's the same width as in windows.resize handler
       
        const width = gridRef.current.offsetWidth + widthDelta; 
        console.log(width);

        //document.getElementById(gridRef.curret)
        let key = 'lg';
        if (width < breakpoints.lg) {
            key = 'md';
        }
        if (width < breakpoints.md) {
            key = 'sm';
        }


        console.log('key', key);
        // if (width < breakpoints.sm) {
        //     key = 'xs';
        // }

        // if (width < breakpoints.xs) {
        //     key = 'xxs';
        // }

        const newLayouts = {...allLayouts, [key]: sizingData};
        setTrackedLayouts(newLayouts);

        //setTrackedLayouts({sm: [{ i: "item1", x: 0, y: 0, w: 6, h: 3 }]});
        //console.log('trackedLayouts', trackedLayouts, {...allLayouts, [key]: sizingData})

        

        
    };

    

    return (<>
    
    {/* breakpoints: 
    <br/>
    ={JSON.stringify(breakpoints)}=
    <br/>
    ={currentBreakpoint}=
    <br/>
    ={gridWidth}=
    <br/>
    ={windowWidth}=
    <br/>
    =Current: {JSON.stringify(currentLayout)}=
    <br/>
    =Tracked: {JSON.stringify(trackedLayouts)}= */}

    <div id='d' ref={gridRef} style={{border: '2px solid red'
        // , width: '50%'
        }}>
    <ResponsiveReactGridLayout 
        isDraggable={props.isEditMode}
        isResizable={props.isEditMode}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onLayoutChange={onLayoutChange2}
        onBreakpointChange={onBreakpointChange}
        //layouts={layouts}
        //layouts={{lg: initialLayouts}}
        //layouts={initialLayouts}
        layouts={trackedLayouts}
        breakpoints={breakpoints}
        cols={cols}
        margin={[20, 20]}
        rowHeight={100} // Each row is 30px high
        width={1200}
    >
        {charts.map((chart, ix)=>{
            return (
            
            <div key={`item${ix+1}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                {/* =={ix+1}== */}
                
                <div><b>{chart.name}</b> ({chart.chartType})</div>
                {/* TODO: need the [X close] button that would remove the chart from the dashboard */}
                <div style={{transform: 'scale(1)', transformOrigin: 'center', width: '100%', height: '100%'}}>
                    <ChartSwitcher chart={chart} />
                </div>
            </div>)
        })}
    </ResponsiveReactGridLayout>
    </div>
    </>
    );

    return (<>
    <div style={{display: 'flex', border: '2px solid red', height: '600px'}}>
    <ResponsiveReactGridLayout 
    className="layout"
    layouts={layouts}
    rowHeight={120}>
        {charts.map((chart, ix)=>{
            debugger;
            return (<div key={ix} className='grid-item layouts' style={{border: '2px solid blue', height: '400px'}}>
                <div>{chart.name}({chart.chartType})</div>

                <ChartSwitcher chart={chart} />
            </div>)
        })}
    </ResponsiveReactGridLayout>
    </div>
    </>)
}