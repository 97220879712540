import parse from "html-react-parser";
import { getFullContent2 } from "api/search";
import { notify } from "Components/Notification";
import { useEffect, useMemo, useRef, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const MyArticleContent = props=>{

  
    const isContentWithHighlights = useMemo(()=>{
        const result = !!(props.simpleSearchText || props.advancedSearchRows.length);
        return result;
    }, [props.simpleSearchText, props.advancedSearchRows]);


    const [isTextOverflow, setIsTextOverflow] = useState(false);

    const modeWithLongView = useMemo(()=>{
        const result = (isContentWithHighlights && isTextOverflow);
        // console.log('modelWithLongView: ', JSON.stringify(result));
        return result;
    }, [isContentWithHighlights, isTextOverflow]);

    const [modes, setModes] = useState(modeWithLongView ? ['ShortView', 'LongView', 'FullArticle'] : ['ShortView', 'FullArticle']);

    useEffect(()=>{
        setModes(modeWithLongView ? ['ShortView', 'LongView', 'FullArticle'] : ['ShortView', 'FullArticle']);
    }, [modeWithLongView]);

    const initialMode = props.showFullContent ? modes[modes.length - 1] : modes[0];
    const [currentMode, setCurrentMode] = useState(initialMode); 

    const [isFullContentLoading, setIsFullContentLoading] = useState(false);

    useEffect(()=>{
        // console.log('2 isTextOverflow', isTextOverflow, 'modeWithLongView', modeWithLongView);
        const updatedModes = modeWithLongView ? ['ShortView', 'LongView', 'FullArticle'] : ['ShortView', 'FullArticle'];
        // console.log('updatedModes', updatedModes);
        setModes(updatedModes);
    }, [isTextOverflow, currentMode]);


    const [articleFullContent, setArticleFullContent] = useState(null); // temp ugly solution to load the full content of an article, where content is "search results" (chunks with highlights)


    const requestFullArticle = (documentId)=>{

        if (articleFullContent || !isContentWithHighlights || props.showFullContent){
            // content already loaded, or the current content does not have highlights (it's already full content, just trimmed)
            // or when props.showFullContent, then we already have props.full_content_with_highlights
            return;
        }
        setIsFullContentLoading(true);
        getFullContent2({ 
            documentId, 
            simpleSearchText: props.simpleSearchText, 
            AdvancedSearchRows: props.AdvancedSearchRows 
        }).then (res=>{
            debugger;
            console.log(res);
            setArticleFullContent(res.data.result.content);
        }).catch((err)=>{
            console.log(err);
            notify.error("Failed to get full article");
            setCurrentMode(modeWithLongView ? 'LongView' : 'ShortView'); // previous step before the FullArticle
        }).finally(()=>{
            setIsFullContentLoading(false);
        });

    };


    useEffect(()=>{
        setArticleFullContent(null); // we need to reset the loaded full content when documentId changes (otherwise it will show the full content of a previous article)

        const initialMode = props.showFullContent ? modes[modes.length - 1] : modes[0];
        setCurrentMode(initialMode); // modes go to the first (unless it's showFullContent for everyone !)
    }, [props.documentId]);

    useEffect(()=>{
        const initialMode = props.showFullContent ? modes[modes.length - 1] : modes[0];
        setCurrentMode(initialMode); // modes go to the first (unless it's showFullContent for everyone !)
    }, [props.showFullContent])

    // function copied from previous ResultCard component:
    const escapeHtmlExceptCertainTags = (htmlString) => {
        // First, escape all HTML to treat as text
        let escapedHtml = htmlString
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;");
    
        // Define patterns for <em> and <br/> tags
        const allowedTagPatterns = {
          em: /&lt;(\/?em)&gt;/g,
          br: /&lt;br\s*\/?&gt;/g,
        };
    
        // Unescape <em> and <br/> tags
        escapedHtml = escapedHtml.replace(allowedTagPatterns.em, "<$1>");
        escapedHtml = escapedHtml.replace(allowedTagPatterns.br, "<br/>");
    
        return escapedHtml;
    };
    

    

    const textContainer = useRef();
    const textBlock = useRef();

    function handleResize() {
        // debugger
        // inside here we need to calculate the area of to components
        if (currentMode === 'FullArticle'){
            // when mode is fullArticle, the text container is expanded, and text will never be clipped, no need to look at the areas
            return;
        }
        if (textContainer.current && textBlock.current){
            const textContainerRect = textContainer.current.getBoundingClientRect();
            const textBlockRect = textBlock.current.getBoundingClientRect();

            const textHeightOverlap = textBlockRect.height - textContainerRect.height;

            const isTextOverflowing = textHeightOverlap > 0;
            // console.log('isTextOverflowing: ', JSON.stringify(isTextOverflowing));
            setIsTextOverflow(isTextOverflowing);

        }

    }

    // in each component we need to watch the resize event and calculate the area of the text block rectangle and 
    // the area that the text would take.       
    useEffect(() => {
       

        window.addEventListener("resize", handleResize);
        // handleResize(); // we also need to run it on the first time - this will happen on useEffect with [currentMode] dependency
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    
    
    const isClipped = currentMode === modes[0];

    let viewModeToggleText ='';

    if (modeWithLongView){
        switch(currentMode){
            case modes[0]:
                viewModeToggleText =  'View More';
                break;
            case modes[1]:
                viewModeToggleText = 'View Full Article';
                break;
            case modes[2]:
                viewModeToggleText = 'View Less'
                break;
        }
    }
    else{
        switch(currentMode){
            case modes[0]:
                viewModeToggleText = 'View Full Article';
                break;
            case modes[1]:
                viewModeToggleText = 'View Less'
                break;
        }
    }
    
    const style = {position: 'relative',  overflow: 'hidden'};
    if (isClipped){
        style.maxHeight = `${props.numberOfLines || 4}lh`; 
    }

    const handleViewModeToggleClick = ()=>{
        debugger;
        const currentModeIndex = modes.indexOf(currentMode);
        let newModeIndex = (currentModeIndex + 1) % modes.length;

        const newMode = modes[newModeIndex];
        if (newMode == 'FullArticle' && (!props.showFullContent)){
            requestFullArticle(props.documentId); // ideally full article content should be stored outside of this component, but it's okay for now
        }
        setCurrentMode(newMode);
        if (newMode != 'FullArticle'){
            handleResize();
        }
        if (newMode === modes[0]){
            // scroll to the top of the box - this works, but better to scroll to the Article Title (via parent container)
            // textContainer.current.scrollIntoView({
            //     behavior: 'smooth',
            //     block: 'start'
            // });

            props.scrollToTitle();
        }
    };

    useEffect(()=>{
        if (currentMode == modes[0]){
            handleResize();
        }
    }, [currentMode]);


    const contentToDisplay = useMemo(()=>{
        let result;
        if (props.showFullContent){
            if (currentMode == 'FullArticle'){
                result = props.full_content_with_highlights || props.content; // when full_content_with_highlights is empty in showFullContent mode, it means highlights are on other fields and we just show the document content here
            }
            else{
                result = props.content;
            }
        }
        else {
            result = (isContentWithHighlights && currentMode === 'FullArticle' && articleFullContent) ? articleFullContent : props.content;
        }

        const parsed = parse(escapeHtmlExceptCertainTags(result || ''));
        //console.log('parsed', parsed);
        return parsed;
    }, [isContentWithHighlights, currentMode, articleFullContent, props.showFullContent, props.content, props.full_content_with_highlights]);

    
	
    if (!contentToDisplay || !contentToDisplay.length) {
        return <p>Content not available</p>;
    }

    
    // in "FullArticle" mode "Show Less" button can cover the content if the content takes up the whole last line
    // So we must move the Show Less div one line below


    let toggleDivStyle = {
        position: 'absolute', 
        bottom: 0, right: 0, 
        paddingLeft: '60px',
        maskImage: 'linear-gradient(to left, black 80%, transparent)',
    };

    if (currentMode == modes[modes.length - 1]){
        toggleDivStyle = {
            position: 'relative', 
            float: 'right'
        };
    }

    return (<>
    
    <div style={style} ref={textContainer}>
        <p ref={textBlock}>
            {contentToDisplay}
        </p>

        <div style={{
	      ...toggleDivStyle, 
              backgroundColor: 'rgba(255, 255, 255, 1)', 
              
              zIndex: 10,
              background: 'white',
              color: 'blue',
              paddingRight: '12px'
              
              }}
        >

            {isFullContentLoading ? <Spin indicator={antIcon} /> : 
            <a onClick={()=>handleViewModeToggleClick()} style={{cursor: 'pointer', color: '#3c32f2'}}>
                {viewModeToggleText}
            </a>}
        </div>

    </div>
    </>
    );


    



}