import ButtonElement from "Components/ButtonElement";
import { SimpleSearchControl } from "Components/SearchBar/SimpleSearchControl";
import { AdvancedSearchBanner } from "./AdvancedSearchBanner";
import { SortContent2 } from "./HomeHeader/SortContent/SortContent2";
import { ThreeButtonsControl } from "./ThreeButtonsControl";

import style from './style.module.scss';
import { useState } from "react";
import { SaveResultsModal2 } from "./HomeHeader/children/SaveResultsModal2";
import { SavedQueriesModal } from "Components/SavedSearches/SavedQueriesModal";
import { FilterButton } from "./FilterButton";


export const MyHeaderSection2 = props=>{

    const [showSaveModal, setShowSaveModal] = useState(false);

    const [showLoadQueriesDialog,  setShowLoadQueriesDialog] = useState(false);


    const saveSearchSettings = (queryName)=>{
        props.saveSearchSettings(queryName, ()=>{
            setShowSaveModal(false);
        });
    };
   
    return (<>

        <div style={{padding: '8px', position: 'relative'}} className={style['header-section']} >
            <div style={{display: 'flex'}} className={style['header-section-sub']}>

                <FilterButton 
                    filterIsOn={props.taxonomyFilterOpened}
                    onClick={()=>props.setTaxonomyFilterOpened(!props.taxonomyFilterOpened)}
                />
            

                <div style={{marginLeft: '8px'}}>
                    <SimpleSearchControl runSimpleSearch={props.runSimpleSearch} simpleSearchText={props.simpleSearchText}/>
                </div>
            </div>

            <div className={style['header-section-sub2']}>
                <div className={style['header-section-sub2-content']}>
                    <ButtonElement styleProps={{height: '32px'}} onClick={props.handleViewInsightsButtonClick}>
                        <span>View Insights</span>
                    </ButtonElement>

                    {/* Sync Scope button only makes sense if user somehow opens a brand new tab and adds scope=A parameter to the URL */}
                    {/* <ButtonElement styleProps={{margin: '4px', height: '32px'}} onClick={props.syncScope}>
                        <span>Sync Scope</span>
                    </ButtonElement>
                    */}
                    <ButtonElement styleProps={{ marginLeft: '4px', height: '32px'}} onClick={()=>props.setAdvancedSearchVisible(!props.advancedSearchVisible)}>Advanced Search</ButtonElement>
                    
                    <div style={{marginLeft: '4px'}}>
                        <SortContent2
                            sortByRelevanceAvailable={!!props.simpleSearchText}
                            sortingType={props.sortingType}
                            sortingOrder={props.sortingOrder}
                            handleSortingTypeChange={props.handleSortingTypeChange}
                            handleSortingOrderChange={props.handleSortingOrderChange}
                        />
                    </div>
                

                    <div style={{marginLeft: '4px'}}>
                        <ThreeButtonsControl 
                            handleSavedClicked={()=>setShowSaveModal(true)}
                            handleLoadClicked={()=>setShowLoadQueriesDialog(true)}
                            handleExport={()=>props.exportToXls()}
                            exportLoading={props.exportLoading}

                            userProfileId={props.userProfileId}
                            isGroupAdmin={props.isGroupAdmin}
                            
                                
                        />
                    </div>
                </div>

            </div>
        </div>
    
                
        <AdvancedSearchBanner advancedSearchRows={props.advancedSearchRows} clearAdvancedSearch={props.clearAdvancedSearch}/>

        {showSaveModal ? <SaveResultsModal2 
            handleSaveClicked = {(queryName)=>saveSearchSettings(queryName)}
            handleCloseModal = {()=>setShowSaveModal(false)}
        /> : null}

        {showLoadQueriesDialog ? <SavedQueriesModal 
            userEmail={props.userEmail}
            isGroupAdmin={props.isGroupAdmin}
            projectId={props.projectId}
            userProfileId={props.userProfileId}
            defaultQueryId={props.defaultQueryId}
            handleLoadQuery={(record)=>{
                props.handleLoadQuery(record, ()=>{
                    setShowLoadQueriesDialog(false);
                });
            }
            }
            setAsGroupDefaultQuery={props.setAsGroupDefaultQuery}
            handleCloseModal = {()=>setShowLoadQueriesDialog(false)}
        /> : null}
    
    </>);
};