import { loadTaxonomyCounts } from "api/search";
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from "react";
import { TaxonomyFilterCheckboxRow } from "./TaxonomyFilterCheckboxRow";
import { Button, Collapse } from "antd";
import { TaxonomyFilterDate } from "./TaxonomyFilterDate";
import CustomScrollbar from "Components/CustomScrollbar";
import TooltipContainer from "Components/Tooltip";

const {Panel} = Collapse;

export const FilterPanel = props=>{

    const dateFormat = 'YYYY-MM-DD';

    // taxonomies is a list of objects with property "name"
    const [conceptTaxonomies, setConceptTaxonomies] = useState([]);

    // const [taxonomyDateFilterObj, setTaxonomyDateFilterObj] = useState(null);
    const [dateTaxonomies, setDateTaxonomies] = useState([]);

    useEffect(()=>{
        const t = [];
        const d = [];
        props.taxonomies.forEach(x=>{
            // determine if the taxonomy is date: 
            if (x.name == 'Date Published'){
                d.push(x);
                // for each row of the date taxonomy we fill have a dateFilterRow
            }
            else{
                t.push(x)
            }
        });
        setConceptTaxonomies(t);
        setDateTaxonomies(d);

        debugger;
        // the very first taxonomy (date or concept) will need to be expanded: 
        if (d.length){
            handleExpandedTaxonomies([d[0].name], []);
        }
        else if (t.length){
            handleExpandedTaxonomies([], [t[0].name]);
        }
    }, [props.taxonomies])

    
    const [loadedTaxonomies, setLoadedTaxonomies] = useState({});
    const [checkedTaxonomies, setCheckedTaxonomies] = useState({});
    // const [dateFilterObjList, setDateFilterObjList] = useState([]); // date filters can be passed/set separately from the list of available date taxonomy rows
    const [dateFilters, setDateFilters] = useState({}); // dateFilters object keeps the user input for the date controls (not nessarily valid). Once it's valid, dateFilters is copied into validDateFilters and the query for taxonomies is re-run

    const [validDateFilters, setValidDateFilters] = useState({});  // only valid date filters are here. We run query to get taxonomies only when valid date filters are changed

        
    const [projectId, setProjectId] = useState(props.projectId);

    useEffect(()=>{
        setProjectId(props.projectId);
    }, [props.projectId]);

    useEffect(()=>{
        // set from external data which is a state of loaded, checked and dateFilters. And possibly fromChips
        if (!props.externalData){
            return;
        }

        debugger;

        if(props.externalData.fromChips){
            const loadedKeys = Object.keys(loadedTaxonomies);
            setDateFilters(props.externalData.dateFilters);
            loadTaxonomies(loadedKeys, loadedTaxonomies, props.externalData.checked, props.externalData.dateFilters, projectId);
        }
        else{
            const newLoaded = {};
            const keys = Object.keys(props.externalData.loaded);
            for (let k = 0; k < keys.length; k++){
                const key = keys[k];

                const loadedRow = props.externalData.loaded[key]

                newLoaded[key] = loadedRow.map(x=>{
                    const checkedRow = props.externalData.checked[key];
                    if (!checkedRow){
                        return x;
                    }
                    const checkedValue = checkedRow.some(c=>c.value == x.value);
                    if (checkedValue){
                        return {...x, checked: true}
                    }
                    else{
                        return x;
                    }
                });
            }


            setLoadedTaxonomies(newLoaded);

            setCheckedTaxonomies({...props.externalData.checked});
        
            // TODO: update the dates for modes 1 and 2, check if the incoming filters are valid
            const newValidFilters ={};
            if (props.externalData.dateFilters){
                Object.keys(props.externalData.dateFilters).forEach(dateKey=>{
                    const filter = props.externalData.dateFilters[dateKey];
                    const isValid = isDateObjValid(filter);
                    if (!isValid){
                        return;
                    }
                    if (filter.dateFilterMode == 1){
                        // from a date until now:
                        const to = moment().startOf('day').format(filter.dateFormat);
                        fitler.dateObj = {...filter.dateObj, to};

                    }
                    else if (filter.dateFilterMode == 2){
                        // from last N days:
                        const now = moment().startOf('day');
                        const to = now.format(filter.dateFormat);
                        const from = now.add(-filter.numberOfDays, 'days').format(filter.dateFormat);
                        filter.dateObj = {from, to};
                    }

                    newValidFilters[dateKey] = filter;
                });
            }
            
            // setDateFilters(props.externalData.dateFilters);
            // setValidDateFilters({...props.externalData.dateFilters});

            setDateFilters(newValidFilters);
            setValidDateFilters({...newValidFilters});

            debugger;
            loadTaxonomies(keys, newLoaded, props.externalData.checked, newValidFilters, projectId);
            
        }

    }, [props.externalData]);


    const loadTaxonomies = (expandedTaxonomyNamesToLoad, loadedTaxonomies, checkedTaxonomies, dateFilters, projectId)=>{
        // reload expandedTaxonomyNamesToLoad and merge them with loadedTaxonomies
        // TODO: trace this method
        debugger;
        
        const loadedKeys = Object.keys(loadedTaxonomies);

        const taxonomyFilterRowsForLoadCounts = []; 

        for (let k = 0; k < loadedKeys.length; k++){
            const taxonomyKey = loadedKeys[k];
            const taxonomyDataRows = loadedTaxonomies[taxonomyKey];

            for (let v = 0; v < taxonomyDataRows.length; v++){
                const taxonomyDataRow = taxonomyDataRows[v];
                if (taxonomyDataRow.checked){
                    const row = {
                        fieldName: taxonomyKey,
                        fieldValue: taxonomyDataRow.value
                    };

                    taxonomyFilterRowsForLoadCounts.push(row);
                }
            }
        }

        // these need to be taken from dateFilters:
        // if (taxonomyDateFilterObj && (taxonomyDateFilterObj.dateRangeValueTo || taxonomyDateFilterObj.dateRangeValueFrom)){
        //     taxonomyFilterRowsForLoadCounts.push({
        //         fieldName: 'Date Published',  
        //         dateRangeValueTo: taxonomyDateFilterObj.dateRangeValueTo,
        //         dateRangeValueFrom: taxonomyDateFilterObj.dateRangeValueFrom,
        //         dateFormat: taxonomyDateFilterObj.dateFormat
        //     });
        // }

        if (dateFilters){
            debugger;
            Object.keys(dateFilters).forEach(dateKey => {
                const taxonomyDateFilterObj = dateFilters[dateKey];
                if (isDateObjValid(taxonomyDateFilterObj)){
                    taxonomyFilterRowsForLoadCounts.push({
                        fieldName: dateKey, 
                        dateRangeValueTo: taxonomyDateFilterObj.dateObj.to,
                        dateRangeValueFrom: taxonomyDateFilterObj.dateObj.from,
                        dateFormat: taxonomyDateFilterObj.dateFormat
                    });
                }
            });
        }

        
        Promise.all(expandedTaxonomyNamesToLoad.map(t=>{
            return loadTaxonomyCounts(t, taxonomyFilterRowsForLoadCounts, projectId).then(res=> ({values: res.data.result, taxonomyName: t}))
        })).then(res=>{
            const loaded = {};
            const newForChecked = {};
            res.forEach(x=>{
                loaded[x.taxonomyName] = x.values;
                newForChecked[x.taxonomyName] = checkedTaxonomies[x.taxonomyName] || [];
            });

            const newlyLoadedKeys = Object.keys(loaded);

            for (let t = 0; t < newlyLoadedKeys.length; t++){
                const key = newlyLoadedKeys[t];
                const taxonomyValues = checkedTaxonomies[key];
                if (taxonomyValues){
                    for (var k = 0; k < taxonomyValues.length; k++){
                        const valueRow = taxonomyValues[k];
                        if (valueRow.checked){
                            const value = valueRow.value;
                            const loadedRows = loaded[key];
                            for (let x = 0; x < loadedRows.length; x++){
                                if (loadedRows[x].value == value){
                                    loadedRows[x].checked = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            setLoadedTaxonomies(loaded);

            const newChecked = {...checkedTaxonomies, ...newForChecked};
            setCheckedTaxonomies(newChecked);

            if (props.updateLoadedAndCheckedTaxonomies){
                props.updateLoadedAndCheckedTaxonomies(loaded, newChecked, dateFilters);
            }


        }).catch(err=>{
            debugger;
            console.log(err);
        });

    };

    const [prevExpandedKeys, setPrevExpandedKeys] = useState([]); // everything is collapsed

    
    const onTaxonomyOrDateExpandedChange = (newExpandedTaxonomies) => {
        // happens when we expand / collapse a taxonomy
        // newExpandedTaxonomies is a list of newly expanded taxonomies (all expanded taxonomies including the one just clicked)
        // When taxonomies are expanded/collapsed, we need to run a query that reloads the counts and merges the results with checked bits
        // to do that, we prepare taxonomiesToLoad and dateFiltersToApply
        debugger;
        console.log('running onTaxonomyOrDateExpandedChange',newExpandedTaxonomies );
        

        // when we expand the list of taxonomies, we get the full list of what's expanded in the UI
        // some of them are dates, some of them are concepts
        const newExpandedDateTaxonomies = newExpandedTaxonomies.filter(x => dateTaxonomies.some(d=>d.name == x));
        const newExpandedConceptTaxonomies = newExpandedTaxonomies.filter(x => conceptTaxonomies.some(c=>c.name == x));

        console.log('newExpandedDateTaxonomies',newExpandedDateTaxonomies );
        console.log('newExpandedConceptTaxonomies',newExpandedConceptTaxonomies );

        handleExpandedTaxonomies(newExpandedDateTaxonomies, newExpandedConceptTaxonomies);

    };

    const handleExpandedTaxonomies = (newExpandedDateTaxonomies, newExpandedConceptTaxonomies)=>{

        // when we expand a date, we need to make sure that the dateFilters object has a corresponding key: 
        const newDateFilters = {...dateFilters};
        let dateFiltersAdded = false;
        newExpandedDateTaxonomies.forEach(key=>{
            if (!dateFilters[key]){
                dateFiltersAdded = true;
                newDateFilters[key] = {};
            }
        });

        if (dateFiltersAdded){
            setDateFilters(newDateFilters);
            return; // when we just opened a date filter, we don't have anything to reload 
        }

        if (!newExpandedDateTaxonomies.length){
            setDateFilters([]);
        }


        // now look at newExpandedConceptTaxonomies: 

        let allExpandedConceptTaxonomiesLoaded = true;

        const oldLoadedKeys = Object.keys(loadedTaxonomies);

        for (let i = 0; i < newExpandedConceptTaxonomies.length; i++){
            if (!loadedTaxonomies[newExpandedConceptTaxonomies[i]]){
                allExpandedConceptTaxonomiesLoaded = false;
                break;
            }
            // if (!loadedKeys.some(x => x==allExpandedTaxonomies[i])){
            //     // that means a new item has been expanded
            //     allExpandedTaxonomiesLoaded = false;
            //     break;
            // }
        }

        if (allExpandedConceptTaxonomiesLoaded){
            // this means we have just collapsed a taxonomy

            let loadedHasMoreThanExpanded = false;
            let newLoadedTaxonomies = {...loadedTaxonomies};

            // check which old loaded taxonomies will be removed:
            for (let i = 0; i < oldLoadedKeys.length; i++){
                if (!newExpandedConceptTaxonomies.some(x =>x == oldLoadedKeys[i])){
                    loadedHasMoreThanExpanded = true;
                    delete newLoadedTaxonomies[oldLoadedKeys[i]];
                    // but keep them in checkedTaxonomies
                }
            }

            if (loadedHasMoreThanExpanded){
                setLoadedTaxonomies(newLoadedTaxonomies);

                if (props.updateLoadedAndCheckedTaxonomies){
                    props.updateLoadedAndCheckedTaxonomies(newLoadedTaxonomies, checkedTaxonomies, newDateFilters);
                }

            }
            return;
        }

        debugger;
        loadTaxonomies(newExpandedConceptTaxonomies, loadedTaxonomies, checkedTaxonomies, validDateFilters, projectId);

        
    };


    const expandedKeysFromDatesAndTaxonomies = useMemo(()=>{
        const loadedTaxonomiesKeys = Object.keys(loadedTaxonomies);
        //const dateFilterObjKeys = dateFilterObjList.map(x=>x.fieldName);
        const dateFilterObjKeys = Object.keys(dateFilters);
        return [...loadedTaxonomiesKeys, ...dateFilterObjKeys];
    }, [loadedTaxonomies, dateFilters]);


    const reapplyCheckmarksToLoadedTaxonomyValues = (updatedKeys, checkedTaxonomies, loaded)=>{
        debugger;
        // TODO: does something
        for (let k = 0; k < updatedKeys.length; k++){
            const rowToUpdateKey = updatedKeys[k];
            const existingTaxonomyDataRowThatMightHaveChecks = checkedTaxonomies[rowToUpdateKey];
            if (existingTaxonomyDataRowThatMightHaveChecks){
                for (let v = 0; v < existingTaxonomyDataRowThatMightHaveChecks.length; v++){
                    const taxonomyDataRow = existingTaxonomyDataRowThatMightHaveChecks[v];
                    if (taxonomyDataRow.checked){
               
                        const value = taxonomyDataRow.value;

                        const loadedRows = loaded[rowToUpdateKey];
                        for (let j = 0; j < loadedRows.length; j++){
                            if (loadedRows[j].value == value){
                                loadedRows[j].checked = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
    };

    const dateFilterRowIsValid = dateFilterRow=>{
        if (!dateFilterRow.mode){
            return false;
        }
        if (dateFilterRow.mode != 'range' && dateFilterRow.mode != 'last-n-days' && dateFilterRow.mode != 'from-date-in-past'){
            return false;
        }
        if (dateFilterRow.mode == 'range'){
            if (!dateFilterRow.dates){
                return false;
            }
            const {from, to} = dateFilterRow.dates;
            const dateFormat = dateFilterRow.dateFormat
            if (!from || !to || !dateFormat){
                return false;
            }

            const fromMoment = moment(from, dateFormat);
            if (!fromMoment.isValid()){
                return false;
            }

            const toMoment = moment(from, dateFormat);
            if (!toMoment.isValid()){
                return false;
            }
            return true;    
        }

        if (dateFilterRow.mode == 'from-date-in-past'){
            //...
        }

        if (dateFilterRow.mode == 'last-n-days'){
            //...
        }

        return false;

        
    };

    const isDateObjValid = obj =>{
        if (!(obj.dateFilterMode === 0 || obj.dateFilterMode === 1 || obj.dateFilterMode === 2)){
            return false;
        }

        if (obj.dateFilterMode  === 0){
            // range, both to and from must be there
            const dateObj = obj.dateObj;
            if (!dateObj) {
                return false;
            }

            if (!dateObj.from){
                return false;
            }

            if (!obj.dateFormat){
                return false;
            }


            // from and to are strings:
            if (!moment(dateObj.from, obj.dateFormat).isValid()){
                return false;
            }

            if (!dateObj.to){
                return false;
            }
            // from and to are strings:
            if (!moment(dateObj.to, obj.dateFormat).isValid()){
                return false;
            }

            return true;
        }

        if (obj.dateFilterMode  === 1){
            // from a date until now, from must be there
            const dateObj = obj.dateObj;
            if (!dateObj) {
                return false;
            }

            if (!dateObj.from){
                return false;
            }

            // from is a string:
            if (!moment(dateObj.from, obj.dateFormat).isValid()){
                return false;
            }

            return true;
        }

        if (obj.dateFilterMode  === 2){
            // from last N days, but we get both the date from (as a string) and N days
            const dateObj = obj.dateObj;
            if (!dateObj) {
                return false;
            }

            if (!dateObj.from){
                return false;
            }

            // from is a string, it's set at the moment we clicked on the filter control:
            if (!moment(dateObj.from, obj.dateFormat).isValid()){
                return false;
            }

            // we also look at numberOfDays:
            const numberOfDays = obj.numberOfDays;
            if (!numberOfDays && numberOfDays !==0){
                return false;
            }

            return true;
        }
    };

    const updateTaxonomiesWithDates = (combinedDateObj, dateTaxonomyName) =>{
        debugger;
        // we received the dateObject, need to record it into the dateFilters:
        const newDateFilters = {...dateFilters, [dateTaxonomyName]: combinedDateObj};
        setDateFilters(newDateFilters);
        // dateFilters are always there to reflect the user input
        
        const newValidDateFilters = {};
        const dateFilterKeys = Object.keys(newDateFilters);

        dateFilterKeys.forEach(key=>{
            const f = newDateFilters[key];
            // TODO: we need to return another flag {isValid, isIncomplete}, and if it's isValid: false, isIncomplete: true, 
            // then we use the old valid filter instead of the one we just messed up with
            const isValid = isDateObjValid(f);
            debugger;
            if (isValid){
                newValidDateFilters[key] = {...f};
            }
        });
        
        let validDateFiltersChanged = JSON.stringify(newValidDateFilters) != JSON.stringify(validDateFilters);
        if (validDateFiltersChanged){
            setValidDateFilters(newValidDateFilters);
        }


        if (validDateFiltersChanged){


            const loadedTaxonomiesKeys = Object.keys(loadedTaxonomies); 

            if (loadedTaxonomiesKeys.length){
                // load the new counts and update the stuff in the parent
                // we need to prepare the list of filter rows based on what's currently loaded
                // if no taxonomies loaded - we don't need to update anything

                const filterRowsFromLoadedTaxonomies = [];
                
                    
                for (let k = 0; k < loadedTaxonomiesKeys.length; k++){
                    const taxonomyKey = loadedTaxonomiesKeys[k];
                    const checkedValues = checkedTaxonomies[taxonomyKey];
                    if (checkedValues){
                        checkedValues.forEach(taxonomyRow=>{
                            const row = {
                                fieldName: taxonomyKey,
                                fieldValue: taxonomyRow.value
                            };
                            filterRowsFromLoadedTaxonomies.push(row);
                        });
                    }
                    // const taxonomyDataRows = loadedTaxonomies[taxonomyKey];

                    // for (let v = 0; v < taxonomyDataRows.length; v++){
                    //     const taxonomyDataRow = taxonomyDataRows[v];
                    //     if (taxonomyDataRow.checked){
                    //         const row = {
                    //             fieldName: taxonomyKey,
                    //             fieldValue: taxonomyDataRow.value
                    //         };

                    //         filterRowsFromLoadedTaxononies.push(row);
                    //     }
                    // }
                }
                
                // only valid date filters are applied:
                Object.keys(newValidDateFilters).forEach(dateKey=>{
                    const dateFilter = newValidDateFilters[dateKey];
                                    
                    // then convert it to a filter row: 
                    const dateRow = {
                        isDateFilter: true, 
                        fieldName: dateKey,  
                        dateRangeValueTo: dateFilter.dateObj.to,
                        dateRangeValueFrom: dateFilter.dateObj.from,
                        // numberOfDays is translated into dateFrom, we don't pass it 
                        dateFormat: dateFilter.dateFormat
                    };

                    filterRowsFromLoadedTaxonomies.push(dateRow);
                    
                });

                Promise.all(loadedTaxonomiesKeys.map(taxonomyKey =>{
                    // for each taxonomy we need to filter out the rows related to this taxonomy:
                    return loadTaxonomyCounts(taxonomyKey, filterRowsFromLoadedTaxonomies.filter(r=>r.fieldName != taxonomyKey), projectId).then(res=> ({values: res.data.result, taxonomyName: taxonomyKey}))
                })).then(res=>{
                    debugger;

                    // we get the newly loaded taxonomies, need to reaplly the checked marks
                    const newlyLoadedTaxonomies = {};

                    res.forEach(x=>{
                        newlyLoadedTaxonomies[x.taxonomyName] = x.values;
                    });
                    debugger;

                    const updatedKeys = res.map(x=>x.taxonomyName);

                    reapplyCheckmarksToLoadedTaxonomyValues(updatedKeys, checkedTaxonomies, newlyLoadedTaxonomies);

                    setLoadedTaxonomies(newlyLoadedTaxonomies);



                    if (props.updateLoadedAndCheckedTaxonomies){
                        // TODO: need another handler here that would take taxonomyFilters and dateFilters
                    
                        //props.updateLoadedAndCheckedTaxonomies(newlyLoadedTaxonomies, checkedTaxonomies, filterRowsFromLoadedTaxonomies);
                        props.updateLoadedAndCheckedTaxonomies(newlyLoadedTaxonomies, checkedTaxonomies, newValidDateFilters);
                    }
                })


            }
            else{
                if (props.updateLoadedAndCheckedTaxonomies){
                    // only filters have changed, we didn't reload the taxonomies because they were all closed
                    
                    props.updateLoadedAndCheckedTaxonomies(loadedTaxonomies, checkedTaxonomies, newValidDateFilters);
                }

            }
        }
        else{
            // valid filters didnt changed
            //console.log('skip invalid date filter', combinedDateObj);
            console.log("valid filters didn't change");
        }

    };

    const updateTaxonomiesWithDates2 = (combinedDateObj, dateTaxonomyName) =>{
        debugger;
        // TODO: does something



        let newDateFilter = dateFilters[dateTaxonomyName];


        const {dateFilterMode, dateObj} = combinedDateObj;

        

        let rowDateObj = null;

        if (dateObj.from && dateObj.from.isValid()){        
            rowDateObj = {dateRangeValueFrom: dateObj.from.format(dateFormat), dateFormat, dateFilterMode, numberOfDays: dateObj.numberOfDays};
        }

        if (dateObj.to && dateObj.to.isValid()){
            if (!rowDateObj){
                rowDateObj = {dateRangeValueTo: dateObj.to.format(dateFormat), dateFormat, dateFilterMode, numberOfDays: dateObj.numberOfDays};
            }
            else {
                rowDateObj = {...rowDateObj, dateRangeValueTo: dateObj.to.format(dateFormat), dateFilterMode, numberOfDays: dateObj.numberOfDays};
            }
        }


        // if ((taxonomyDateFilterObj && !rowDateObj) || (!taxonomyDateFilterObj && rowDateObj) || (taxonomyDateFilterObj && rowDateObj)){

        // convert rowDateObj to dateFilter value:
        if (!rowDateObj){
            newDateFilter = null;
        }
        else{
            // convert rowDateObj to dateFilterObj:
            newDateFilter = {dateFormat};
            if (rowDateObj.dateFilterMode === 0){
                newDateFilter.mode = 'range';
                newDateFilter.dates = {from: rowDateObj.dateRangeValueFrom, to: rowDateObj.dateRangeValueTo};
            }
            if (rowDateObj.dateFilterMode === 1){
                newDateFilter.mode = 'last-n-days';
                newDateFilter.numberOfDays = rowDateObj.numberOfDays;
                newDateFilter.dates = {};
                
            }
            if (rowDateObj.dateFilterMode === 2){
                newDateFilter.mode = 'from-date-in-past';
                newDateFilter.numberOfDays = rowDateObj.numberOfDays;
                newDateFilter.dates = {};
            }
        
            // setTaxonomyDateFilterObj(rowDateObj);

        }

        const newDateFilters ={...dateFilters, [dateTaxonomyName]: newDateFilter};
        if (!newDateFilter){
            delete newDateFilters[dateTaxonomyName];
        }

        // now prepare the filter rows and re-run the query:
        const loadedTaxonomiesKeys = Object.keys(loadedTaxonomies); 
        // if (keys.length){ 
        // we need to re-run the query with date filters, even if taxonomy filters are empty

            const filterRows = [];

            for (let k = 0; k < loadedTaxonomiesKeys.length; k++){
                const taxonomyKey = loadedTaxonomiesKeys[k];
                const taxonomyDataRows = loadedTaxonomies[taxonomyKey];

                for (let v = 0; v < taxonomyDataRows.length; v++){
                    const taxonomyDataRow = taxonomyDataRows[v];
                    if (taxonomyDataRow.checked){
                        const row = {
                            fieldName: taxonomyKey,
                            fieldValue: taxonomyDataRow.value
                        };

                        filterRows.push(row);
                    }
                }
            }

            // const allDateFiltersForQuery = {...dateFilters, [dateTaxonomyName]: newDateFilter}

            if (newDateFilters){
                const dateKeys = Object.keys(newDateFilters);
                dateKeys.forEach(key=>{
                    const dateFilterRow = newDateFilters[key];

                    const isValid = dateFilterRowIsValid(dateFilterRow);
                    // TODO: need to determine if the row is valid: 

                    if (isValid){
                        filterRows.push({
                            fieldName: key,  
                            dateRangeValueTo: dateFilterRow.dates.to,
                            dateRangeValueFrom: dateFilterRow.dates.from, // numberOfDays is translated into dateFrom 
                            dateFormat: dateFilterRow.dateFormat // this is the format of the date, we don't pass the last-n-days type of things (date filter only takes dates)
                        });
                    }

                });
                
            }

            // we need to refresh the counts based on the date filter: 
            if (loadedTaxonomiesKeys.length){
                Promise.all(loadedTaxonomiesKeys.map(t=>{
                    return loadTaxonomyCounts(t, filterRows, projectId).then(res=> ({values: res.data.result, taxonomyName: t}))
                    })).then(res=>{

                        const loaded = {};

                        res.map(x=>{
                            loaded[x.taxonomyName] = x.values;
                        });


                        const updatedKeys = res.map(x=>x.taxonomyName);

                        reapplyCheckmarksToLoadedTaxonomyValues(updatedKeys, checkedTaxonomies, loaded);

                        setLoadedTaxonomies(loaded);

                        if (props.updateLoadedAndCheckedTaxonomies){
                            props.updateLoadedAndCheckedTaxonomies(loaded, checkedTaxonomies, newDateFilters);
                        }


                    }).catch(err=>{
                        debugger;
                        console.log(err);
                    });
            }
        // }
        // else{
        //     if (props.updateLoadedAndCheckedTaxonomies){
        //         props.updateLoadedAndCheckedTaxonomies(loadedTaxonomies, checkedTaxonomies, newDateFilters);
        //     }
        // }


        setDateFilters(newDateFilters);

    };

    const updateTaxonomySelection = (allExpandedTaxonomies, modifiedTaxonomyName, updateModifiedTaxonomy = false)=>{
        // happens when user clicks a checkbox in a taxonomy
        // userUpdatedTaxonomies - new state of checked taxonomies (object where keys are expanded/loaded taxonomies, and values are arrays of name-count-checked objects)
        // modifiedTaxonomyName - name of the taxonomy where a checkbox was clicked
        // flag for the behaviour when we reload the counts of other values within this taxonomy with the name 'modifiedTaxonomyName'
        debugger;

        const modifiedTaxonomyCheckedValues = allExpandedTaxonomies[modifiedTaxonomyName].filter(v=>v.checked).map(v=>({value: v.value, checked: v.checked})); // only checked values in the given taxonomy
        const newCheckedTaxonomies = {...checkedTaxonomies, [modifiedTaxonomyName]: modifiedTaxonomyCheckedValues};
        setCheckedTaxonomies(newCheckedTaxonomies);

        // we need to re-run the queries for other opened taxonomies:
        const filterRows = [];

        // useUpdatedTaxonomies is the picture of the user selection
        const allExpandedTaxonomyKeys = Object.keys(allExpandedTaxonomies);

        for (let k = 0; k < allExpandedTaxonomyKeys.length; k++){
            const taxonomyKey = allExpandedTaxonomyKeys[k];
            const taxonomyDataRows = allExpandedTaxonomies[taxonomyKey];

            for (let v = 0; v < taxonomyDataRows.length; v++){
                const taxonomyDataRow = taxonomyDataRows[v];
                if (taxonomyDataRow.checked){
                    const row = {
                        fieldName: taxonomyKey,
                        fieldValue: taxonomyDataRow.value
                    };

                    filterRows.push(row);
                }
            }
        }

        if (validDateFilters){
            Object.keys(validDateFilters).forEach(dateKey =>{
                const dateFilter  = validDateFilters[dateKey];
                filterRows.push({
                    isDateFilter: true, 
                    fieldName: dateKey,
                    dateRangeValueTo: dateFilter.dateObj.to,
                    dateRangeValueFrom: dateFilter.dateObj.from,
                    dateFormat: dateFilter.dateFormat
                });
            });
        }

        const keysForTaxonomiesToUpdate = updateModifiedTaxonomy ? allExpandedTaxonomyKeys : allExpandedTaxonomyKeys.filter(x=>x!==modifiedTaxonomyName);
        //console.log('keysForTaxonomiesToUpdate', keysForTaxonomiesToUpdate);
        if (keysForTaxonomiesToUpdate.length){
            Promise.all(keysForTaxonomiesToUpdate.map(taxonomyKey=>{
            return loadTaxonomyCounts(taxonomyKey, filterRows, projectId).then(res=> ({values: res.data.result, taxonomyName: taxonomyKey}))
            })).then(res=>{
                debugger;
                console.log(res);

                // we got the loaded taxonomies
                const loaded = {};
                res.map(x=>{
                    loaded[x.taxonomyName] = x.values;
                });

                // now need to merge the loaded with userUpdatedTaxonomies - based on the keys !
                debugger;
                console.log('loaded', loaded);
                console.log('userUpdatedTaxonomies', allExpandedTaxonomies);

                const updatedKeys = keysForTaxonomiesToUpdate;

                reapplyCheckmarksToLoadedTaxonomyValues(updatedKeys, newCheckedTaxonomies, loaded);


                if (updateModifiedTaxonomy){
                    setLoadedTaxonomies(loaded);

                    // TODO: in this handler we will pass the dateFilters (validDateFilters)
                    if (props.updateLoadedAndCheckedTaxonomies){
                        props.updateLoadedAndCheckedTaxonomies(loaded, newCheckedTaxonomies, validDateFilters);
                    }
                }
                else {
                    const newLoadedTaxonomies = {...loaded, [modifiedTaxonomyName]: allExpandedTaxonomies[modifiedTaxonomyName]};
                    setLoadedTaxonomies(newLoadedTaxonomies);

                    if (props.updateLoadedAndCheckedTaxonomies){
                        props.updateLoadedAndCheckedTaxonomies(loaded, newCheckedTaxonomies, validDateFilters);
                    }

                }

            }).catch(err=>{
                debugger;
                console.log(err);
            });
        }
        else {
            console.log("we didn't reload any taxonomies (made a selection within current taxonomy)");
            // we didn't reload any taxonomies (made a selection within current taxonomy)
            const newLoadedTaxonomies = {...loadedTaxonomies, [modifiedTaxonomyName]: allExpandedTaxonomies[modifiedTaxonomyName]};


            setLoadedTaxonomies(newLoadedTaxonomies);

            if (props.updateLoadedAndCheckedTaxonomies){
                props.updateLoadedAndCheckedTaxonomies(newLoadedTaxonomies, newCheckedTaxonomies, dateFilters);
            }

        }

    };



    const getTaxonomyPanelTitle = tName=>{
        const visibleTaxonomies = loadedTaxonomies[tName] || [];
        const everChecked = checkedTaxonomies[tName] || [];

        //const countChecked = checkedTaxonomies[tName]?.length || 0;
        const countChecked = everChecked.filter(x=> visibleTaxonomies.some(v=>v.value == x.value)).length;
        if (countChecked){
            return `${tName} (${countChecked})`
        }
        else {
            return tName;
        }
    };

    const handleClearAllTaxonomyFiltersClicked = ()=>{
        debugger;
        const newTaxonomies = {...loadedTaxonomies};
        const keys = Object.keys(newTaxonomies);
        keys.forEach(tName => {
            newTaxonomies[tName] = newTaxonomies[tName].map(v=>({...v, checked: false}));
        });
        setLoadedTaxonomies(newTaxonomies);
        setCheckedTaxonomies({});
        const newDateFilters = {};
        Object.keys(dateFilters).forEach(dateKey =>{
            newDateFilters[dateKey] = {};
        });
        setValidDateFilters({});
        
        props.clearAllTaxonomyFilters();
    };

    return (<>
    
    <div style={{display: 'block', overflowY: 'hidden', width: '100%'}}>
        {/* <div style={{display: 'inline'}}>
        =={JSON.stringify(loadedTaxonomies)}==
        <br/>
        =={JSON.stringify(dateFilters)}==
        </div> */}

        <div style={{display: 'flex', padding: '6px', justifyContent: 'space-evenly'}}>
            <div>
            <TooltipContainer
                title='Clear all Taxonomy Filters'
                placement="bottom"
                overlayStyle={{width:'auto'}}
            >
                <Button style={{width: '100%', padding: '4px', height: '32px'}} onClick={handleClearAllTaxonomyFiltersClicked} >Clear All</Button>
            </TooltipContainer>
            </div>
            <div>
                <TooltipContainer
                    title='Apply selected Taxonomy Filters'
                    placement="bottom"
                    overlayStyle={{width:'auto'}}
                >
                    <Button style={{width: '100%', padding: '4px', height: '32px'}} onClick={props.runSearch} >Apply</Button>
                </TooltipContainer>
            </div>
        </div>



        <div style={{ display: 'block', maxHeight: 'calc(100vh - 10rem)'}}>

            <CustomScrollbar className="filter-scroller">
                <div>


            
                    {/* {props.needDateFilter ? <> <TaxonomyFilterDate updateTaxonomiesWithDates={updateTaxonomiesWithDates} dateFilterObj={taxonomyDateFilterObj}/> </> : null} */}


                    <Collapse onChange={onTaxonomyOrDateExpandedChange} activeKey={expandedKeysFromDatesAndTaxonomies} >

                        {dateTaxonomies.map((d, ix)=>{
                        return (<Panel header={d.name} key={d.name}>

                            
                            
                            <TaxonomyFilterDate dateFormat='DD/MM/YYYY' updateTaxonomiesWithDates={dateObj=>updateTaxonomiesWithDates(dateObj, d.name)} dateFilterObj={dateFilters[d.name]}/>
                        </Panel>);

                        })}

                        {conceptTaxonomies.map((t, ix)=>{
                            return (<Panel header={getTaxonomyPanelTitle(t.name)} key={t.name} style={{borderBottom: '1px solid hsla(0,0%,80%,.5)'}}>

                                <TaxonomyFilterCheckboxRow
                                    sortOrder={props.sortOrder}
                                    taxonomyName={t.name}
                                    loadedTaxonomies={loadedTaxonomies}
                                    updateTaxonomies={updateTaxonomySelection}
                                />
                            </Panel>);

                        })}
                    </Collapse>
                </div>
            </CustomScrollbar>
        </div>


    </div>
    </>);
}