import { APIInstance } from "./apiInstance";
import { BASE_URL } from "./constants";

export function Search(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/reMultipleSearch", payload);
}

/**
 *
 * @param {{emailId: string | null, uid: string, user_tags: string[]}} payload
 * @returns
 */
export function addTags({ emailId, uids, user_tags, uidsReturn, append }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/updatewithid", { emailId, uids, keywords: user_tags, uidsReturn, append });
}

export function saveUserTags(projectId, documentId, userTags) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/save-user-tags", { projectId, documentId, userTags});
}


export async function getKeywords(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.get("/genericCollection");
}

/**
 *
 * @param {{emailId: string | null}} param
 * API Method to get Filter field list from backend
 * @returns {{data: string[]}}
 */
export function getFilterGenericFields({ emailId }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  // return api.get('/genericfeilds');
  return api.post("/genericfeilds", { emailid: emailId });
}

/**
 *
 * @param {{emailId: string | null}} param
 * API Method to get Filter field list with details like dataType and isFilter
 * @returns {{data: {dataType: number, isFilter: boolean, name: string}[]}}
 */
export function getAllFieldsList({ emailId }) {
  // OBSOLETE
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/FilterFields", { emailId: emailId });
}

export function loadFilterFields(projectId){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/FilterFields1", {projectId});
}

export function loadTaxonomyCounts(taxonomyName, taxonomyFilterRows, projectId){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH
  });

  const api = instance.api;
  return api.post("/LoadTaxonomyCounts", {taxonomyName, taxonomyFilterRows, projectId}, {noCancel: true});  // pass noCancel flag to allow multiple calls from Promise.All
}


export function runSearchApi(payload){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH
  });

  const api = instance.api;
  return api.post("/SearchWithFilters", payload); 
}

export async function getRelationshipChartData(){
  // TODO: add possible payload
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH
  });

  const api = instance.api;
  return api.post("/GetRelationshipChartData"); 
}


/**
 * API Method to get Filter list of specific field from backend
 * @param {{fieldName: string}} payload
 * @returns {{data: string[]}}
 */
export function getFiltersByFields({ fieldName, emailId }) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/SearchByField", { fieldName, emailId });
}

export function addRecentSearch(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/addsearchhistory", payload);
}

// this function is to replace addRecentSearch, it simply saves all filter settings
export function saveSearchSettings(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/save-search-settings", payload);
}

export function getRecent(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/getsearchhistory", payload);
}



/**
 *
 * @param {{pageNumber: number, pageSize: number, emailid: string, isSavedResult: boolean, avoidDetails: boolean}} payload
 * @returns
 */
// obsolete, replaced by getSavedQueriesPaginated
export function getSavedSearchList(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetsearchhistoryPageSize", payload);
}


// to replace the above
export function getSavedQueriesPaginated(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.get("/get-saved-queries-paginated", {params: payload});
}


// loads a list of 
export function getSavedQueriesLookup(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.get("/get-saved-queries-lookup");
}



// obsolete, replaced by getSavedQueriesAll
export function getAllSavedSearch() {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.get("/SummaryOfAllSearchhistories");
}

/**
 *
 * @param {{id: number,emailid: string}} payload
 * @returns
 */
export function getSavedSearchById(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetsearchhistoryById", payload);
}


export function deleteSavedQuery(payload) {
  // payload is just id
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/delete-saved-query", payload);
}


// obsolete, replaced by delete-saved-query
export function deleteSaved(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/deleteSearch", payload);
}

export function advancedSearch(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });
  const api = instance.api;
  return api.post("/advancesearch", payload);
}

export function addPrivateTags(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/addPrivateTags", payload);
}

export function updateQuickCodes(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/updateQuickCodes", payload);
}

export function updateSearchHistory(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/updatesearchhistory", payload);
}

// obsolete, used by the old frontend only
export function getFullContent(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetFullArticle", payload);
}

export function getFullContent2(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/GetFullArticle2", payload);
}

export function filteFieldsByCount(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });
  const api = instance.api;

  return api.post("/GetFieldData", payload);
}

export function exportAsXlsx(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/AdvanceSearchXLS", payload);
}

// same as the old one but takes filters
export function exportAsXlsx2(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/AdvanceSearchXLS2", payload, {noCancel: true, responseType: 'arraybuffer'});
}

export function exportDocumentByIdToExcel(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/ExportDocumentByIdToExcel", payload, {noCancel: true, responseType: 'arraybuffer'});
}


export function exportDocuments(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/exportDocuments", payload, {noCancel: true});
}

export function searchConcepts(searchText){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/search-concepts", {advanceSearchKeyword: searchText});
}


export function searchConcepts2(searchText){
  const instance = new APIInstance({
    baseURL: BASE_URL.SEARCH,
  });

  const api = instance.api;
  return api.post("/search-concepts2", {advanceSearchKeyword: searchText});
}
